import { createApp } from "vue";

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import "vuetify/styles";
import { createVuetify, ThemeDefinition } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi.mjs";

import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./shared/router";

import VueApexCharts from "vue3-apexcharts";

const jornadaLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: "#EEEEEE",
    surface: "#FFFFFF",
    primary: "#FF8B3D",
    logo: "#438EFF",
    navy: "#0f2733",
    "primary-darken-1": "#3700B3",
    secondary: "#9575CD",
    "secondary-accent": "#6200EA",
    "secondary-darken-1": "#018786",
    error: "#CC3333",
    info: "#2196F3",
    success: "#22CC44",
    warning: "#FB8C00",
    "orange-dark": "#E52A0B",
    base: "#F2F4F5",
  },
};
const vuetify = createVuetify({
  theme: {
    defaultTheme: "jornadaLightTheme",
    themes: { jornadaLightTheme },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
      fa,
    },
  },
  components,
  directives,
});
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
app.use(router).use(pinia).use(vuetify).use(VueApexCharts).mount("#app");
