<script setup lang="ts">
import { defineProps, ref } from "vue";
import { useNavbarStore } from "@/shared/store/navbar_store";
import { useDisplay } from "vuetify";
import { useSidebarStore } from "../store/sidebar_store";

const props = defineProps({
  routeTitle: String,
});

const display = ref(useDisplay());

const sidebarStore = useSidebarStore();

const navbarStore = useNavbarStore();
</script>

<template>
  <v-app-bar color="base" prominent app fixed class="py-3" elevation="1">
    <v-toolbar-title
      class="flex text-start text--text font-weight-bold"
      :class="display.mdAndDown ? 'subtitle-1' : 'text-h4'"
    >
      <div class="py-2">
        <v-app-bar-nav-icon
          @click="sidebarStore.toggleRail"
          v-if="sidebarStore.getRail && display.mdAndDown"
          ><template v-slot:default>
            <v-icon
              size="35"
              color="logo"
              style="border: 2px solid #ff7d27; border-radius: 50%"
            >
              mdi-chevron-left
            </v-icon>
          </template></v-app-bar-nav-icon
        >

        {{ props.routeTitle }}
      </div>
    </v-toolbar-title>
    <v-col class="mx-3">
      <v-row>
        <v-toolbar-title
          class="flex text-end text--text"
          :class="display.mdAndDown ? 'text-body-1' : 'text-h5'"
        >
          {{
            navbarStore.userName ? navbarStore.userName : "Usuário Indefinido"
          }}
        </v-toolbar-title>
      </v-row>

      <v-row>
        <v-toolbar-title
          class="flex text-end"
          style="color: #ff8b3d"
          :class="display.mdAndDown ? 'text-body-2' : 'subtitle-1'"
        >
          {{
            navbarStore.userRole ? navbarStore.userRole : "Perfil Indefinido"
          }}</v-toolbar-title
        >
      </v-row>
    </v-col>
  </v-app-bar>
</template>
<style scoped></style>
