import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/modules/login/ui/views/LoginView.vue"),
  },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   component: () => import("@/modules/dashboard/ui/views/DashboardView.vue"),
  // },
  {
    path: "/ciclo-pedido",
    name: "Ciclo de Vida do Pedido",
    component: () =>
      import("@/modules/ciclo_vida_pedido/ui/views/CicloVidaPedido.vue"),
  },
  {
    path: "/expedicao",
    name: "Expedição",
    component: () => import("@/modules/expedicao/ui/views/ExpedicaoView.vue"),
  },
  {
    path: "/producao",
    name: "Produção",
    meta: {
      subtitle:
        "Acompanha a separação do insumo do pedido conforme operações de: pré-venda ou pronta entrega",
    },
    component: () => import("@/modules/producao/ui/views/ProducaoView.vue"),
  },
  {
    path: "/integracao",
    name: "Integração",
    meta: {
      subtitle: "Valida as integrações dos pedidos entre a Loja e ERP Winthor",
    },
    component: () => import("@/modules/integracao/ui/views/IntegracaoView.vue"),
  },
  {
    path: "/financiamento",
    name: "Financiamento",
    meta: {
      subtitle:
        "Instrui, Valida e acompanha a aprovação do crédito do pedido do cliente junto a órgãos financeiros",
    },
    component: () =>
      import("@/modules/financiamento/ui/views/FinanciamentoView.vue"),
  },
  {
    path: "/reconhecimento",
    name: "Reconhecimento",
    meta: {
      subtitle:
        "Reconhece o pagamento do cliente final pelas vias de cobrança: boleto, pix, cartão e financiamento",
    },
    component: () =>
      import("@/modules/reconhecimento/ui/views/ReconhecimentoView.vue"),
  },
  {
    path: "/transporte",
    name: "Transporte",
    meta: {
      subtitle:
        "Trata do andamento do agendamento do pedido junto a transportadora",
    },
    component: () => import("@/modules/transporte/ui/views/TransporteView.vue"),
  },
  {
    path: "/reports",
    name: "Reports",
    component: () => import("@/modules/report/ui/views/ReportView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
